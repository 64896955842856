<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26">
              <path d="M13 0.1875C7.027344 0.1875 2.1875 2.339844 2.1875 5C2.1875 7.660156 7.027344 9.8125 13 9.8125C18.972656 9.8125 23.8125 7.660156 23.8125 5C23.8125 2.339844 18.972656 0.1875 13 0.1875 Z M 2.1875 6.3125L2.1875 10C2.1875 12.660156 7.027344 14.8125 13 14.8125C18.972656 14.8125 23.8125 12.660156 23.8125 10L23.8125 6.3125C23.8125 8.972656 18.972656 11.125 13 11.125C7.027344 11.125 2.1875 8.972656 2.1875 6.3125 Z M 2.1875 11.3125L2.1875 15C2.1875 17.660156 7.027344 19.8125 13 19.8125C18.972656 19.8125 23.8125 17.660156 23.8125 15L23.8125 11.3125C23.8125 13.972656 18.972656 16.125 13 16.125C7.027344 16.125 2.1875 13.972656 2.1875 11.3125 Z M 2.1875 16.3125L2.1875 20C2.1875 22.660156 7.027344 24.8125 13 24.8125C18.972656 24.8125 23.8125 22.660156 23.8125 20L23.8125 16.3125C23.8125 18.972656 18.972656 21.125 13 21.125C7.027344 21.125 2.1875 18.972656 2.1875 16.3125Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Bulk Upload</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="bulkUploads" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.qId }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="TITLE">
              {{ item.title }}
            </md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="usedFor" md-label="USED FOR">{{ item.usedFor }}</md-table-cell>
            <md-table-cell md-sort-by="uploadedBy" md-label="UPLOAD BY" class="text-victoria">{{ item.uploadedBy }}</md-table-cell>
            <md-table-cell md-sort-by="uploadedAt" md-label="UPLOADED AT">{{ item.uploadedAt }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onEdit" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { PromptDetails, BulkUploadModal, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { bulkUploads } from '@/data/master/qatm/question-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        BulkUploadModal
  },
  data() {
      return {
          currentIndex: 0,
          component: 'BulkUploadModal',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    bulkUploads() {
      if(this.pagination) {
        return bulkUploads.slice(this.paginate.start, this.paginate.end);
      }
      return bulkUploads;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return bulkUploads.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'BulkUploadModal';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>