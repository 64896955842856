<template>
    <div>
        <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2"><md-icon class="text-victoria mr-2">insert_link</md-icon><small class="text-black text-uppercase">Important links</small></h2>
        <div class="md-gutter container grid import_link media_gallary">
            <div class="md-layout-item md-gutter">
            <span v-for="(link,i) in getLink.external_links" :key="i">
                <div class="md-layout-item gallery">
                <span class="flex align-center">
                    <!-- <img width="242" height="363" :src="this.Og_image" alt="material image"> -->
                    <md-icon class="text-victoria">attach_file</md-icon>
                    <a style="word-break: break-all" class="text-victoria ml-2" target="_blank" :href="link">{{getLink.external_link_titles[i]}}</a>
                </span>
                </div>
            </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
      getLink:{
        type: Object
      }
    },
  data() {
    return {
    }
  },
}
</script>