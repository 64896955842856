<template>
  <form id="myForm" @submit.prevent="onSubmit" class="p-0" style="">
    <div class="my-3 mt-2">
      <h2 class="mb-4">Create New Explaination</h2>
    </div>
    <div>
      <div class="my-3">
        <label class="text-uppercase font-bold">Choose Section</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 'reading', label: 'Reading' },
            { id: 'writing', label: 'Writing' },
            { id: 'speaking', label: 'Speaking' },
            { id: 'listening', label: 'Listening' },
          ]"
          v-model="questionSection"
        />
      </div>
      <div class="my-3 flex">
        <SelectComponent
          :items="getQuestionTypes"
          label="Choose Item"
          class="w-74"
          @input="onQuestionTypes"
          v-model="form.question_type_id"
        />
        <span class="ml-2 w-full">
        <InputFieldComponent
            label="Question Index"
            @input="onQuestionIntex"
            v-model="form.index"
          />
        </span>
      </div>
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Write the Explanation</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
      />
    </div>
    <div class="flex flex-col mt-3">
      <div class="my-0 mb-2">
        <label class="text-uppercase inline-block font-bold mb-3">Note</label>
        <TextEditor
          :label="false"
          v-model.trim="form.notes"
        />
      </div>
    </div>
  <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Choose Material</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my- justify-end">
              <Matreial :name='"Browse"' :type_name="type_name" v-model="form.materials[0]"/>
              <md-button @click="materialCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in materialCount" :key="i">
              <Matreial :name='"Browse"' :type_name="type_name" v-model="form.materials[i]"/>
              <md-button @click="materialCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Write important links</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my-2">
              <InputFieldComponent  placeholder="URL" v-model="form.external_links[0]" class="basis mr-2"/>
              <InputFieldComponent  placeholder="Link text" v-model="form.external_link_titles[0]" class="basis" />
              <md-button @click="linkCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in linkCount" :key="i">
              <InputFieldComponent placeholder="URL" v-model="form.external_links[i]" class="basis mr-2" />
              <InputFieldComponent placeholder="Link text" v-model="form.external_link_titles[i]" class="basis" />
              <md-button @click="linkCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3 flex justify-between">
      <div>
        <md-button @click="dialog(false)" class="bg-default text-uppercase rounded">Cancel</md-button>
        <md-button type="submit" class="bg-victoria text-white text-uppercase rounded">Save & SET</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import Matreial from "./materialFile.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    SelectComponent,
    InputFieldComponent,
    TextEditor,
    Matreial
  },
  data() {
    return {
      form: {
        question_type_id: "",
        title: "",
        materials: [],
        external_links: [],
        external_link_titles: [],
        active: true,
        is_default: false,
        is_archived: false,
      },
      type_name: {
        name:'explanation',
        id: 'e_id'
      },
      materialCount: 0,
      showMediaDialog: false,
      linkCount: 0,
      questionSection: '',
      url_name: '',
      merge:[],
      Q_type_id: '',
      Q_index: ''
    };
  },
  computed: {
      ...mapGetters({
          getQuestionTypes: "question/getQuestionTypes"
      })
  },
  watch: {
      questionSection(value) {
          this.actGetQuestionTypes(`?section=${value}`)
      }
  },
  methods: {
    ...mapActions({
        actGetQuestionTypes: "question/actGetQuestionTypes",
        actSaveExplanation: "explanation/actSaveExplanation",
        actListExplanation: "explanation/actListExplanation",
        submitQuestion: 'question/actSubmitQuestion',
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    input(val){
      console.log(val)
      if(val.url){
        this.url_name = val.url;
      } else{
        this.url_name = val[0].name;
      }
    },
    modalClose(){
        this.showMediaDialog = false
    },
    upload(attachments) {
      let item = [];
      if(attachments.length > 0) {
        attachments.forEach(element => {
        if(element) {
          item.push(element.name)
        }
        });
      }
      return item;
    },
    onClear(){
        var frm = document.getElementById('myForm');
        frm.reset();  // Reset
        return false; // Prevent page refresh
    },
    onQuestionTypes(val){
      //console.log('sssdw',val)
      this.Q_type_id = val
    },
    onQuestionIntex(val){
      
      this.Q_index = val
    },
    onSubmit() {
      console.log('id_type',this.Q_index)
      const forms = {
        question_type_id: this.Q_type_id,
        index: this.Q_index ,
        prompt_type: "DEFAULT",
        prompt: "this is question",
        preparation_time_type: "DEFAULT",
        preparation_time: 0,
        answer_time_type: "DEFAULT",
        answer_time: 0,
        source: "",
        frequency: "",
        active: true,
        title: null,
        type: "QUESTION",
        question_options: [],
        is_requested: false
      }
       this.submitQuestion(forms)
        .then(response => {
          this.setShowSnackbar(response.message);
          console.log('test',response.question_id);
          //this.question_id = response.question_id;
          this.form.question_type_id = this.Q_type_id;
          this.form.questions = { [response.question_id] : { is_default: false } }
          this.actSaveExplanation(this.form)
            .then(({ message }) => {
              this.setShowSnackbar(message);
              this.dialog(false);
              this.actListExplanation(``);
            })
        }).catch(error => {
           this.setShowSnackbar(error.response.data.message);
        })
      
    },
  },
  validations: {
    form: {
      item: { required },
      prompt: { required },
      module: { required },
      section: { required },
    },
  },
  mounted() {
    this.actGetQuestionTypes(`?section=reading`);

  }
};
</script>
