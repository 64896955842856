<template>
    <div class="p-6" style="min-width: 700px">
      <DialogTitle v-if="!justShow" label="Study Guide" align="center" />
      <div>
        <div class="my-4">
            <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2"><md-icon class="text-victoria mr-2">description</md-icon><small class="text-black text-uppercase">Guidlines</small></h2>
            <p v-html="studyGuide.title"></p>
        </div>
        <div class="my-4">
            <Gallary :getMaterials="studyGuide.materials"/>
        </div>
        <div class="my-4">
            <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2"><md-icon class="text-victoria mr-2">insert_link</md-icon><small class="text-black text-uppercase">Important links</small></h2>
            <div class="md-gutter container grid import_link media_gallary">
              <div class="md-layout-item md-gutter">
                <span v-for="(link,i) in  studyGuide.external_links" :key="i">
                  <div class="md-layout-item gallery">
                    <span class="flex align-center">
                      <md-icon class="text-victoria">attach_file</md-icon>
                      <a :href="link">{{studyGuide.external_link_titles[i]}}</a>
                    </span>
                  </div>
                </span>
              </div>
            </div>
        </div>
        <div class="my-4">
            <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2 mt-4"><md-icon class="text-sky mr-2">video_library</md-icon><small class="text-black text-uppercase">Required Lesson Should be completed</small></h2>
            <div class="md-gutter container grid import_lesson media_gallary">
              <div class="md-layout-item md-gutter">
                <span v-for="lesson in getLesson" :key="lesson.id">
                  <div class="mb-2">
                    <span>
                      <md-icon>videocam</md-icon>
                      <a :href="lesson.file_url">{{lesson.file_name}}</a>
                    </span>
                  </div>
                </span>
              </div>
            </div>
        </div>
        <div class="my-4">
            <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2"><md-icon class="text-green mr-2">quiz</md-icon><small class="text-black text-uppercase">Required Quiz Should be completed</small></h2>
            <div class="md-gutter container grid import_quiz media_gallary">
              <div class="md-layout-item md-gutter">
                <span v-for="lesson in getQuiz" :key="lesson.id">
                  <div class="mb-2">
                    <span>
                      <md-icon>import_contacts</md-icon>
                      <a :href="lesson.file_url">{{lesson.file_name}}</a>
                    </span>
                  </div>
                </span>
              </div>
            </div>
        </div>
      </div>
      <div v-if="!justShow">
        <div class="flex justify-center">
          <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
          <md-button class="outline-gray-400 bg-victoria text-white text-uppercase rounded">Go to Edit</md-button>
        </div>
      </div>
    </div>
</template>
<script>
import Gallary from "../Materials.vue";
import { DialogTitle } from "@/components";
//const urlMetadata = require('url-metadata')
import { mapGetters, mapMutations } from "vuex";
export default {
    components: {
        //Content,
        Gallary,
        DialogTitle 
    },
    props: {
        justShow: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters({
            studyGuide: "studyGuide/getStudyGuide"
        }),
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog"
        })
    }
}
</script>