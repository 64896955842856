<template>
  <div style="display: contents;">
      <div class="flex justify-between align-center rounded border border-solid border-gray-500 border w-full p-1">
        <p style="width:400px" class="pl-2 text-base material_file_title">{{this.file_name}}</p>
        <div class="rounded text-white p-2 text-uppercase inline-block bg-victoria pointer" @click="showMediaDialog = true">{{name}}</div>
      </div>
      <md-dialog :md-active.sync="showMediaDialog" class="md-dailog__teacher">
          <AddMedia :showMedia="showMedia" @input="input" :type_name="type_name" :materialIds="materialIds" class="basis"/>
          <md-dialog-actions class="mr-4 m-2">
            <md-button class="md-primary " @click="modalClose()">Close</md-button>
            <md-button class="md-primary bg-victoria text-white" @click="modalClose()">Save</md-button>
        </md-dialog-actions>
      </md-dialog>
  </div>  
  </template>
  
  <script>
  import AddMedia from "./addMedia.vue";
  export default {
    components: {
      AddMedia,
    },
     props: {
      type_name: {
        type: Object
      },
      name:{
        type: String
      },
      title: {
        type: String
      },
      materialIds: {
        type: [Array, String]
      }
    },
    data() {
      return {
        showMediaDialog: false,
        file_name: this.title ?? '',
      };
    },
    watch: {
      title(newValue) {
        if (newValue !== this.file_name) {
          this.file_name = newValue;
        }
      },
    },
    methods: {
      input(val){
        console.log(val.id,'ffgfg fdfd')
        if(val.file_name){
          this.file_name = val.file_name;
          this.$emit('input',val.id);
        } 
      },
      showMedia(showMedia){
        console.log(showMedia)
      },
      modalClose(){
          this.showMediaDialog = false
      },
     
    },
  
  };
  </script>