<template>
<div>
  <h2 class="border-b border-b-solid border-gray-300 mb-4 pb-2"><md-icon class="text-black mr-2">handyman</md-icon><small class="text-black text-uppercase">Materials</small></h2>
  <div class="md-gutter container grid media_gallary">
    <div class="md-layout-item md-gutter">
      <span v-for="img in getMaterials" :key="img.id">
        <div class="md-layout-item gallery">
          <a> 
            <span v-if="img.file_type === 'IMAGE'">
              <img width="242" height="363" :src="img.file_url" alt="material image">
            </span>
            <span class="relative" v-if="img.file_type === 'PDF'">
              <md-icon class="flex align-center text-victoria" style="font-size:55px !important">picture_as_pdf</md-icon>
            </span>
            <span class="relative" v-if="img.file_type === 'DOCX'">
              <md-icon class="flex align-center text-victoria" style="font-size:55px !important">text_snippet</md-icon>
              <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 56px;">Docs</p>
            </span>
            <span class="relative" v-if="img.file_type == 'AUDIO'">
              <md-icon class="flex align-center text-victoria" style="font-size:55px !important">audiotrack</md-icon>
              <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 51px;">Audio</p>
            </span>
            <span class="relative" v-if="img.file_type == 'VIDEO'">
              <md-icon class="flex align-center text-victoria" style="font-size:55px !important">ondemand_video</md-icon>
              <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 55px;">Video</p>
            </span>
          </a>
          <p class="image_sub_title">{{img.file_name}}</p>
        </div>
      </span>
    </div>
  </div>
</div>
</template>
<script>
export default {
    props:{
      getMaterials:{
        type: Array
      }
    },
  data() {
    return {
    }
  },
}
</script>
<style scoped>
.material_file_title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.media_gallary{
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.media_gallary .gallery{
  border-radius: 5px;
  float: left;
  padding:4px;
  box-sizing: border-box;
  flex: 1.3344;
}
.media_gallary .gallery.clicked img,.media_gallary .gallery.clicked i{
  opacity: 0.9;
  border: 4px solid #19c11d;
}
.media_gallary .gallery .pdf_title{
  margin-top: -28px;
  padding: 4px;
}
.media_gallary .gallery img,.media_gallary .gallery .md-icon{
  width: 140px;
  height: 130px !important;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #dadce0;
}

.media_gallary .gallery .image_sub_title{
    width: 130px;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
    color: #5f6368;
    margin-left: 5px;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
/* .media_gallary .gallery img:hover,.media_gallary .gallery .md-icon:hover{
  box-shadow: 1px 1px 5px 0px;
  opacity: 0.7;
} */
.media_gallary .header{
  border-bottom: 1px solid #dadce0;
}
</style>