<template>
  <div class="p-6" style="width: 650px">
    <DialogTitle style="margin-left: -10px; margin-right: -10px" :label="material.file_name" align="center" />
    <Content class="my-3" label="Material ID" :text="material.m_id" />
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
        <Content class="my-3" label="Material Type" :text="material.file_type" text-color="text-capitalize" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
        <Content class="my-3" label="File Name" :text="material.file_name" text-color="text-capitalize" />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
        <Content class="my-3" label="Created At" :text="material.created_at" text-color="text-capitalize" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
        <Content class="my-3" label="Created By" :text="material.created_by" text-color="text-capitalize" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
        <Content class="my-3" label="Used for" :text="material.file_for" text-color="text-capitalize"  />
      </div>
    </div>
    <Content class="my-3" label="Description" content-width="w-full" text-color="text-base" :text="material.material_description" />
    <div>
      <p class="text-victoria text-uppercase mb-3">Material Thumbnail</p>
      <img :src="'https://sfo2.digitaloceanspaces.com/kazi-blubird/apical/'+material.thumbnail" alt="">
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
        <Content class="my-3" label="Status" :text="(material.active === 1) ? 'Active' : 'Inactive' " text-color="text-green" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
        <Content class="my-3" label="Special" :text="(material.is_special === 1) ? 'Yes' : 'No' " text-color="text-green" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
        <Content class="my-3" label="Download Accesibility" :text="(material.is_downloadable === 1) ? 'Yes' : 'No' " text-color="text-green" />
      </div>
    </div>
    <div v-if="!justShow">
      <div class="flex justify-center">
        <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
    justShow: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      material: "material/getmaterial"
    })
  },
  methods: mapMutations({
    dialog: "setShowDialog",
  })
}
</script>