<template>
  <form id="myForm" @submit.prevent="onSubmit" class="p-0 sample_answer">
    <DialogTitle v-if="!justShow" label="Sample Answer" align="center" />
    <div class="my-3 mt-2" v-if="content.section != 'SPEAKING'">
      <h2 class="mb-4">Create New {{type_name.name}}</h2>
    </div>
    <div>
      <div class="my-3">
        <label class="text-uppercase font-bold">Choose Section</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 'reading', label: 'Reading' },
            { id: 'writing', label: 'Writing' },
            { id: 'speaking', label: 'Speaking' },
            { id: 'listening', label: 'Listening' },
          ]"
          v-model="questionSection"
        />
      </div>
      {{questionSection}}
      <div class="my-3 flex">
        <SelectComponent
          :items="getQuestionTypes"
          label="Choose Item"
          class="w-74"
          v-model="form.question_type_id"
        />
        <span class="ml-2 w-full">
        <InputFieldComponent
            label="Question Index"
            v-model="form.index"
          />
        </span>
      </div>
    </div>
    <div class="my-3" v-if="questionSection != 'speaking'">
      <label class="text-uppercase inline-block font-bold mb-3">Write the {{type}}</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
      />
    </div>
   <div class="md-layout" v-if="questionSection === 'speaking'">
      <div class="my-3 md-layout-item md-layout md-size-50 body_sample_answer">
        <div>
          <label class="text-uppercase inline-block font-bold mb-3">Male</label>
        </div>
        <div class="md-layout-item flex justify-left mt-0 male">
          <AudioUploader
            class="mt-6 -mt-4"
            v-model.trim="form.resources[0].file_path"
            @input="input"
          />
        </div>
      </div>
      <div class="my-3 md-layout-item md-layout md-size-50 body_sample_answer">
        <label class="text-uppercase inline-block font-bold mb-3 r">Female</label>
        <div class="md-layout-item flex justify-center mt-0 female">
          <AudioUploader
            class="mt-6 -mt-4"
            v-model.trim="form.resources[1].file_path"
          />
        </div>
      </div>
    </div> 
    <div class="my-3 flex justify-end">
      <div>
        <md-button @click="dialog(false)" class="bg-default text-uppercase rounded">Cancel</md-button>
        <md-button type="submit" class="bg-victoria text-white text-uppercase rounded">Save & SET</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import AudioUploader from "@/components/atom/media/AudioUploader";
import { DialogTitle } from "@/components";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    TextEditor,
    RadioButtonComponent,
    SelectComponent,
    InputFieldComponent,
    AudioUploader,
     DialogTitle
  },
  props: {
    type_name: {
      type: Object
    },
    question_id: {
      type: String,
    },
    content:{
      type: Object
    },
    type:{
      type:String
    }
  },
  data() {
    return {
      form: {
        question_type_id: "",
        title: "",
        active: false,
        resources: [
          {
            label:'male',
          },
          {
            label:'female',
          }
        ],
      },
      showMediaDialog: false,
      questionSection: '',
    };
  },
  computed: {
      ...mapGetters({
        getQuestionTypes: "question/getQuestionTypes",
        getSelectedQuestionType: "question/getSelectedQuestionType"
      })
  },
  watch: {
      questionSection(value) {
          this.actGetQuestionTypes(`?section=${value}`)
      }
  },
  methods: {
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSaveSampleAnswer: "sampleAnswer/actSaveSampleAnswer",
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    modalClose(){
        this.showMediaDialog = false
    },
    onClear(){
        var frm = document.getElementById('myForm');
        frm.reset();  // Reset
        return false; // Prevent page refresh
    },
    input(val){
       console.log('update',val) 
       this.value = val 
    },
    onSubmit() {
      if(this.type != 'speaking'){
        this.form.resources= []
      }
      this.actSaveSampleAnswer(this.form)
        .then(({ message }) => {
          this.setShowSnackbar(message);
          this.dialog(false);
          //this.actListExplanation(``);
        })
    },
  },
  mounted() {
    this.actGetQuestionTypes(`?section=reading`);

  }
};
</script>
