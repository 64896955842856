<template>
  <div class="p-6" style="width: 660px">
    <DialogTitle :label="title" bg-color="bg-victoria" align="center" />
    <div class="my-6" v-for="(item, index) in kyes" :key="index">
      <p class="text-victoria text-base text-uppercase">{{ item }}</p>
      <h2 class="text-xl mt-1">{{ content[item] }}</h2>
    </div>
    <div class="mt-16 flex justify-center">
      <md-button @click="dialog(false)" class="outline-gray-500 rounded"
        >Back</md-button
      >
    </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
  },
  props: {
      title: {
          type: String,
          default: () => 'Title goes to here'
        },
        content: {
            type: Object
        }
  },
  computed: {
      kyes() {
          return Object.keys(this.content)
      }
  },
  methods: mapMutations({
    dialog: "setShowDialog",
  }),
};
</script>

<style></style>
