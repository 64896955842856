<template>
  <form id="myForm" @submit.prevent="onSubmit" class="p-0" style="">
    <DialogTitle v-if="!justShow" label="Study Guide" align="center" />
    <div>
      <div class="my-3">
        <label class="text-uppercase font-bold">Choose Section</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 'reading', label: 'Reading' },
            { id: 'writing', label: 'Writing' },
            { id: 'speaking', label: 'Speaking' },
            { id: 'listening', label: 'Listening' },
          ]"
          v-model="questionSection"
        />
      </div>
      <div class="my-3 flex">
        <SelectComponent
          :items="getQuestionTypes"
          label="Choose Item"
          class="w-74"
          v-model="form.question_type_id"
        />
      </div>
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Write the {{type_name.name}}</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
      />
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Choose Material</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my- justify-end">
              <Matreial :name='"Browse"' :type_name="type_name" v-model="form.materials[0]"/>
              <md-button @click="materialCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in materialCount" :key="i">
              <Matreial :name='"Browse"' :type_name="type_name" v-model="form.materials[i]"/>
              <md-button @click="materialCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Write important links</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my-2">
              <InputFieldComponent  placeholder="URL" v-model="form.external_links[0]" class="basis mr-2"/>
              <InputFieldComponent  placeholder="Link text" v-model="form.external_link_titles[0]" class="basis" />
              <md-button @click="linkCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in linkCount" :key="i">
              <InputFieldComponent placeholder="URL" v-model="form.external_links[i]" class="basis mr-2" />
              <InputFieldComponent placeholder="Link text" v-model="form.external_link_titles[i]" class="basis" />
              <md-button @click="linkCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Reuired lesson should be completed</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my-2">
              <Matreial :name='"Browse Lesson"' :type_name="type_name" v-model="form.materials[0]"/>
              <md-button @click="linkCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in linkCount" :key="i">
              <Matreial :name='"Browse Lesson"' :type_name="type_name" v-model="form.materials[i]"/>
              <md-button @click="linkCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <div class="flex align-baseline"> 
        <label class="w-20p text-uppercase inline-block font-bold mb-2">Reuired Quiz should be completed</label>
        <div class="w-85p">
          <div class="ml-20 flex align-center my-2">
              <Matreial :name='"Browse Quiz"' :type_name="type_name" v-model="form.materials[0]"/>
              <md-button @click="linkCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
              </md-button>
          </div>
          <div class="ml-20 flex align-center my-1" v-for="i in linkCount" :key="i">
              <Matreial :name='"Browse Quiz"' :type_name="type_name" v-model="form.materials[i]"/>
              <md-button @click="linkCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
              </md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3 flex justify-between">
      <div class="active">
        <md-checkbox v-if="type_name.id === '0'" v-model="form.active">
          Mark as active
        </md-checkbox>
      </div>
      <div>
        <md-button @click="dialog(false)" class="bg-default text-uppercase rounded">Cancel</md-button>
        <md-button type="submit" class="bg-victoria text-white text-uppercase rounded">Save</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import Matreial from "../materialFile.vue";
import { DialogTitle } from "@/components";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Matreial,
    RadioButtonComponent,
    SelectComponent,
    InputFieldComponent,
    TextEditor,
    DialogTitle
  },
  props: {
    type_name: {
      type: Object
    },
    question_id: {
      type: String,
    }
  },
  data() {
    return {
      form: {
        question_type_id: "",
        title: "",
        materials: [],
        external_links: [],
        external_link_titles: [],
        active: true,
        is_default: false,
        is_archived: false,
      },
      materialCount: 0,
      showMediaDialog: false,
      linkCount: 0,
      questionSection: '',
      url_name: '',
      merge:[]
    };
  },
  computed: {
      ...mapGetters({
          getQuestionTypes: "question/getQuestionTypes"
      })
  },
  watch: {
      questionSection(value) {
          this.actGetQuestionTypes(`?section=${value}`)
      }
  },
  methods: {
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSaveStudyGuide: "studyGuide/actSaveStudyGuide",
      actListStudyGuide: "studyGuide/actListStudyGuide",
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    input(val){
      console.log(val)
      if(val.url){
        this.url_name = val.url;
      } else{
        this.url_name = val[0].name;
      }
    },
    modalClose(){
        this.showMediaDialog = false
    },
    upload(attachments) {
      let item = [];
      if(attachments.length > 0) {
        attachments.forEach(element => {
        if(element) {
          item.push(element.name)
        }
        });
      }
      return item;
    },
    onClear(){
        var frm = document.getElementById('myForm');
        frm.reset();  // Reset
        return false; // Prevent page refresh
    },
    onSubmit() {
      this.actSaveStudyGuide(this.form)
        .then(({ message }) => {
          this.setShowSnackbar(message);
          this.dialog(false);
          this.actListStudyGuide(``);
        })
    },
  },
  validations: {
    form: {
      item: { required },
      prompt: { required },
      module: { required },
      section: { required }
    },
  },
  mounted() {
    this.actGetQuestionTypes(`?section=reading`);

  }
};
</script>
