export const assignToTrainerOrAdmin = [];

const titles = ['Population of Canada', 'Our beautiful Bangladesh', 'We are believing the situation', 'Large changes of environment', 'New world going to dead', 'Technology is power', 'English is our second language']

for (let i = 0; i < 100; i++) {
    assignToTrainerOrAdmin.push({
    id: i,
    qId: 'Q10'+i,
    title: titles[Math.floor(Math.random() * 6)],
    section: 'Speaking',
    bulk: [1,5,8,9,10,16].includes(i),
    item: 'RA',
    branch: 'Dhanmondi',
    assignAt: '25 May, 2020, 9:15am',
    assignTo: 'Kazi Shahin',
    status: 'Complete'
  });
}

export const bulkUploads = [];

for (let i = 0; i < 100; i++) {
    bulkUploads.push({
        id: i,
        qId: 'Q10'+i,
        title: 'Population of canada',
        section: 'Speaking',
        item: 'RS',
        usedFor: 'PTE 2 | PRACTICE 4 | MOCK 2',
        uploadedBy: 'Kazi Shahin',
        uploadedAt: '25 May, 2020, 9:15pm'
    });
    
}

export const communications = [];

for (let i = 0; i < 100; i++) {
    communications.push({
        id: i,
        qId: 'Q10'+i,
        header: 'Population of canada',
        date: '25 Aug, 2019',
        section: 'Speaking',
        item: 'RA',
        reportedBy: 'Cristiano Ronaldo',
        assignTo: 'Kazi Shahin',
        status: 'Solved'
    });
    
}