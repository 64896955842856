<template>
  <div class="p-6" style="min-width: 700px">
        <!-- <label class="mt-4 text-uppercase font-bold ml-4 text-lg">SET Explanation 
           <small class="text-victoria w-75p font-bold text-lg line-height-27">{{explanation.section}}</small>
        </label> -->
        <DialogTitle v-if="!justShow" label="Explanation" align="center" />
        <div class="md-layout-item md-size-100 mt-0 pt-0">
        <div class="p-2 mb-2">
            <div class="my-2">
            <p class="text-uppercase mb-0 font-bold">THE Explanation</p>
            <div class="text-base line-height-27 rounded pb-2"  v-html="explanation.title"></div>
            </div>
            <div class="md-layout md-gutter md-size-100">
            <div class="md-layout-item md-size-100">
                <Gallary :getMaterials="explanation.materials"/>  
            </div>
            </div>
            <div class="mt-4">
            <div class="">
                <LinkLayout :getLink="explanation"/>
            </div>
            </div>
            <div class="mt-4">
            <p class="text-uppercase font-bold border-b border-b-solid border-gray-300 mb-4 pb-2">Note</p>
            <div class="text-base line-height-27 rounded pb-2" v-html="explanation.notes"></div>
            </div>
        </div>
        </div>
        <div v-if="!justShow">
            <!-- <Content class="my-3" label="Status" text-color="text-tahiti-gold" :text="(explanation.active == '1') ? 'Active' : 'Inactive' "/> -->

            <div class="flex justify-center">
                <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
                <md-button class="outline-gray-400 bg-victoria text-white text-uppercase rounded">Go to Edit</md-button>
            </div>
        </div>
  </div>
</template>

<script>
//import Content from "@/components/atom/Content";
import { DialogTitle } from "@/components";
import Gallary from "./Materials.vue";
import LinkLayout from "./LinkMaterial.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
    components: {
        //Content,
        DialogTitle,
        Gallary,
        LinkLayout
    },
    props: {
        justShow: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters({
            explanation: "explanation/getExplanation"
        }),
        materialLength() {
            return this.explanation.materials && this.explanation.materials.length
        }
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog"
        })
    }
}
</script>