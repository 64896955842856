<template>
  <div class="md-gutter container grid">
      <span v-if="isUploading" class="flex relative py-2 px-4 bg-lite-ash rounded-md font-semibold">Uploading<Spinner :loading="isUploading" variant="bar" :diameter="40" :stroke="13" className="w-80p bg-white h-3 rounded" class="relative pl-4 bg-transparent w-33p"/></span>
      <div class="flex justify-between">
          <h3 class="mb-2 flex align-center">
              <img class="mr-1" src="https://img.icons8.com/material/24/000000/opened-folder--v1.png" alt="logo"/>
              Material Gallery
          </h3>
          <SearchBox
          class="p-1 pl-2 w-64 w-xs-48 m-2"
          v-model="keyword"
          placeholder="Search"
          @submit="submitSearchKeyword"
          />
      </div>
        <div class="md-layout-item md-gutter overflow-y" style="max-height: 56.3vh;min-height: 56.3vh;">
            <Spinner v-if="getLoading" :loading="getLoading" :diameter="40" :stroke="3" />
            <span v-for="img in getMaterials" :key="img.id">
                <div @click="img.is_selected !== 1 && selItem(img)" :class="[{clicked: opened.includes(img.id),selected: img.is_selected}]" class="md-layout-item gallery">
                    <a> 
                        <span v-if="img.file_type === 'IMAGE'">
                            <img width="242" height="363" :src="img.file_url" alt="material">
                        </span>
                        <span class="relative" v-if="img.file_type === 'PDF'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">picture_as_pdf</md-icon>
                        </span>
                        <span class="relative" v-if="img.file_type === 'DOCX'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">text_snippet</md-icon>
                            <p class="flex align-center text-victoria" style="position: absolute;top: 85px;left: 56px;">Docs</p>
                        </span>
                        <span class="relative" v-if="img.file_type == 'AUDIO'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">audiotrack</md-icon>
                            <p class="flex align-center text-victoria" style="position: absolute;top: 85px;left: 51px;">Audio</p>
                        </span>
                        <span class="relative" v-if="img.file_type == 'VIDEO'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">ondemand_video</md-icon>
                            <p class="flex align-center text-victoria" style="position: absolute;top: 85px;left: 55px;">Video</p>
                        </span>
                    
                    </a>
                    <p class="image_sub_title">{{img.file_name}}</p>
                </div>
            </span>
            <span v-if="getMaterials.length === 0" class="flex align-center justify-center w-full h-full text-gray-500">
                  No Data
            </span>
        </div>
        <div class="mt-2 pb-2 ml-1" style="border-bottom: 1px solid rgba(166, 166, 166, 0.49);zoom: 90%;">
            <Paginate
              :start="meta.from"
              :end="meta.to"
              :next="meta.current_page"
              :prev="meta.last_page"
              :total="meta.total"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
            />
        </div>
  </div>
</template>
<script>
import {
  Paginate,
  Spinner
  } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { mapGetters,mapActions} from "vuex";
import { query } from "@/utils/queryString";
export default {
components:{
  SearchBox,
  Paginate,
  Spinner
},
props: {
  isUploading: {
    type: Boolean,
    default: () => false
  },
},
data() {
  return {
    urls: '',
    opened:[],
    checkedItems:[],
    merge:[],
    data:[],
    query: '?',
    keyword: '',
  }
},
computed: {
  ...mapGetters({
    getMaterials: "material/getMaterials",
    meta: "material/getMaterialMeta",
    getLoading: "material/getLoading"
  }),
},
watch: {
  keyword(oldValue, newValue) {
    query.set('search', oldValue)
    if(oldValue.length < newValue.length && oldValue.length == 0) {
      this.actMaterialList(`?${query.get()}`);
    }
    if(oldValue.length >= 3) {
      this.actMaterialList(`?${query.get()}`);
    }
  }
},
methods: {
  ...mapActions({
      actMaterialList: "material/actMaterialList",
  }),
  submitSearchKeyword() {
    this.actMaterialList(`?${query.set('search', this.keyword)}`);
  },
  selItem(item){
    const index = this.opened.indexOf(item.id);
    if(index > -1){
      this.opened.splice(index,1) 
    } else {
      this.opened.splice(index-1,1) 
      this.opened.push(item.id);
      this.$emit('input',item);
    }
  },
  onStart(value) {
    this.selectedValue = value;
    if(this.selectedValue == '') {
      query.remove('page');
    }else {
      query.set('page', this.selectedValue);
    }
    this.actMaterialList(`?${query.get()}`);
  },
  onEnd(value) {
    this.selectedValue = value;
    if(this.selectedValue == '') {
      query.remove('page');
    }else {
      query.set('page', this.selectedValue);
    }
    this.actMaterialList(`?${query.get()}`);
  },
  created() {
    this.actMaterialList().finally(() => {
      this.loading = false; 
    });
  },
},

}
</script>