<template>
<div>
    <div class="rounded p-1">
        <div class="rounded text-white p-2 text-uppercas display-inline bg-victoria pointer" @click="$refs.file.click()">
          Upload From Computer
        </div>
        <input class="hidden" type="file" ref="file" @change="getFileContent">
    </div>
    <b class="text-danger" id="scrollTop" v-if="message">{{ message }}</b>
</div>
</template>

<script>
export default {
  props: {
    italic: {
      type: Boolean,
      default: () => false
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    value: {
      
    }
  },
  data() {
    return {
      fileName: null,
      url: null 
    }
  },
  methods: {
    getFileContent(e) {
      this.fileName = e.target.files[0].name;
      //console.log(e.target.files)
      this.$emit('input',e.target.files);
    }
  }
}
</script>

<style>

</style>