<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle label="Create New Material" align="center" />
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-25 md-xsmall-size-100 mb-3">
        <SelectComponent
            label="File Type"
            italic
            class="w-full"
            :placeholder="false"
            :items="[{id: 'DOC', name: 'DOC'},{id: 'AUDIO', name: 'AUDIO'},{id: 'VIDEO', name: 'VIDEO'},{id: 'IMAGE', name: 'IMAGE'}]"
            v-model.trim="$v.form.file_type.$model"
            :message="!$v.form.file_type.required && $v.form.file_type.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-75 md-xsmall-size-100 mb-3">
        <InputFieldComponent
            italic
            label="File Name"
            v-model.trim="$v.form.file_name.$model"
            :message="!$v.form.file_name.required && $v.form.file_name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-60 md-xsmall-size-100 mb-3">
        <SelectComponent
            label="File For"
            italic
            class="w-full"
            :placeholder="false"
            :items="[{id: 'RA', name: 'RA'},{id: 'DI', name: 'DI'},{id: 'ASQ', name: 'ASQ'},{id: 'WE', name: 'WE'}]"
            v-model.trim="$v.form.file_for.$model"
            :message="!$v.form.file_for.required && $v.form.file_for.$dirty ? 'Field is required' : null"
        />
      </div>

      <div class="md-layout-item md-size-60 md-xsmall-size-100 mb-3">
        <FileUploadComponent
            :requiredLabel="true"
            label="Choose thumbnail" class="w-full"
            v-model.trim="form.thumbnail"
            path="materials"
            @input="handleFileUploadThumbnail"
        />
      </div>
      <div class="md-layout-item md-size-60 md-xsmall-size-100 mb-3">
        <FileUploadComponent
            :requiredLabel="true"
            label="Choose material" class="w-full"
            v-model.trim="form.file_path"
            path="materials"
            @input="handleFileUpload"
        />
        OR
        <InputFieldComponent
            italic
            placeholder="Paste link here"
            v-model.trim="form.file_url"
        />
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100 flex align-center">
        <md-checkbox
            class="mt-6"
            v-model.trim="$v.form.is_downloadable.$model"
        >Allow to Download</md-checkbox>
        <span class="text-sm text-danger" v-if="!$v.form.is_downloadable.required && $v.form.is_downloadable.$dirty">Field is required</span>
      </div>
      <div class="md-layout-item md-size-85 md-xsmall-size-100 mb-3">
        <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Material Description</label>
        <TextEditor
            :label="false"
            v-model.trim="$v.form.material_description.$model"
            :message="!$v.form.material_description.required && $v.form.material_description.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="flex align-center md-layout-item md-size-50 md-xsmall-size-100 mb-3">
        <md-checkbox
            true-value="true"
            false-value="false"
            v-model.trim="$v.form.is_hidden_from_student.$model"
        >Hide from Students</md-checkbox>
        <span class="text-sm text-danger" v-if="!$v.form.is_hidden_from_student.required && $v.form.is_hidden_from_student.$dirty">Field is required</span>
      </div>

      <div class="flex align-center md-layout-item md-size-50 md-xsmall-size-100 mb-3">
        <TagComponent
            label="WRITE TAGS (MAX 3)"
            italic
            v-model.trim="form.tag_data"
        />
      </div>

      <div class="flex justify-end align-center md-layout-item md-size-50 mb-3">
        <md-button type="submit" class="bg-victoria text-white">Create</md-button>
      </div>

    </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  FileUploadComponent,
  InputFieldComponent,
  TagComponent,
} from "@/components"
import TextEditor from "@/components/atom/form/TextEditor";
import { renameObject, renameFile } from "@/store/upload";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions } from "vuex";

export default {
  components: {
    DialogTitle,
    SelectComponent,
    TextEditor,
    InputFieldComponent,
    TagComponent,
    FileUploadComponent,
  },
  data() {
    return {
      value: true,
      form: {
        file_type: "",
        file_name: "",
        file_for: "",
        thumbnail: "",
        file_path: "",
        file_url: "",
        material_description: "",
        is_downloadable: true,
        is_special: false,
        active: true,
        is_hidden_from_student: false,
        tag_data: [],
      }
    }
  },
  methods: {
    handleFileUploadThumbnail(value){
      const path = 'materials/'+value.name;
      this.form.thumbnail = path.toLowerCase();
    },
    handleFileUpload(value){
      const path = 'materials/'+value.name;
      this.form.file_path = path.toLowerCase();
    },
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actMaterialsCreate: "material/actMaterialsCreate",
      actUpdateMaterial: "material/actUpdateMaterial",
      actMaterialList: "material/actMaterialList"
    }),
    onSubmit() {
      console.log(this.form);
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.actMaterialsCreate(this.form)
          .then(({ message, material_id }) => {
            console.log(material_id);
            this.setShowSnackbar(message);
            this.dialog(false);
            let file_path = renameFile(this.form.file_path,'materials_'+material_id);
            let thumbnail = renameFile(this.form.thumbnail,'materials_'+material_id);
            renameObject(this.form.file_path, file_path);
            renameObject(this.form.thumbnail, thumbnail);
            this.form.file_path = file_path;
            this.form.thumbnail = thumbnail;

            this.actUpdateMaterial({
              material_id: material_id,
              payload: {...this.form}
            });
            this.actMaterialList(``);
      })
      this.setShowSnackbar('Material saved successfully !');
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    }

  },
  validations: {
    form: {
      file_type: {required},
      file_name: {required},
      file_for: {required},
      thumbnail: {required},
      material_description: {required},
      is_downloadable: {required},
      is_hidden_from_student: {required},
    }
  }
};
</script>

<style></style>
