<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" fill="#5B5B5B">
              <path d="M8 3.9003906C6.3545455 3.9003906 5 5.2549361 5 6.9003906L5 41.900391C5 43.545845 6.3545455 44.900391 8 44.900391L42 44.900391C43.645455 44.900391 45 43.545845 45 41.900391L45 13.099609 A 1.0001 1.0001 0 0 0 44.707031 12.392578L36.507812 4.1933594 A 1.0001 1.0001 0 0 0 35.800781 3.9003906L8 3.9003906 z M 8 5.9003906L13 5.9003906L13 16.900391C13 18.545845 14.354545 19.900391 16 19.900391L33 19.900391C34.645455 19.900391 36 18.545845 36 16.900391L36 6.5136719L43 13.513672L43 41.900391C43 42.454936 42.554545 42.900391 42 42.900391L37 42.900391L37 27.900391C37 26.254936 35.645455 24.900391 34 24.900391L16 24.900391C14.354545 24.900391 13 26.254936 13 27.900391L13 42.900391L8 42.900391C7.4454545 42.900391 7 42.454936 7 41.900391L7 6.9003906C7 6.3458452 7.4454545 5.9003906 8 5.9003906 z M 15 5.9003906L34 5.9003906L34 16.900391C34 17.454936 33.554545 17.900391 33 17.900391L16 17.900391C15.445455 17.900391 15 17.454936 15 16.900391L15 5.9003906 z M 28 7.9003906 A 1.0001 1.0001 0 0 0 27 8.9003906L27 14.900391 A 1.0001 1.0001 0 0 0 28 15.900391L31 15.900391 A 1.0001 1.0001 0 0 0 32 14.900391L32 8.9003906 A 1.0001 1.0001 0 0 0 31 7.9003906L28 7.9003906 z M 29 9.9003906L30 9.9003906L30 13.900391L29 13.900391L29 9.9003906 z M 16 26.900391L34 26.900391C34.554545 26.900391 35 27.345845 35 27.900391L35 42.900391L15 42.900391L15 27.900391C15 27.345845 15.445455 26.900391 16 26.900391 z M 9 38.900391L9 40.900391L11 40.900391L11 38.900391L9 38.900391 z M 39 38.900391L39 40.900391L41 40.900391L41 38.900391L39 38.900391 z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Draft</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="assignToTrainerOrAdmin" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.qId }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="TITLE">
              {{ item.title }}
              <span v-if="item.bulk" class="ml-2 rounded px-1 bg-tahiti-gold text-white">bulk</span>
            </md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-sort-by="assignAt" md-label="ASSIGN AT">{{ item.assignAt }}</md-table-cell>
            <md-table-cell md-sort-by="assignAt" md-label="LAST MODIFIED AT">{{ item.assignAt }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onShowDetails" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { PromptDetails, PromptCreate, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { assignToTrainerOrAdmin } from '@/data/master/qatm/question-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        PromptCreate
  },
  data() {
      return {
          currentIndex: 0,
          component: 'PromptCreate',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    assignToTrainerOrAdmin() {
      if(this.pagination) {
        return assignToTrainerOrAdmin.slice(this.paginate.start, this.paginate.end);
      }
      return assignToTrainerOrAdmin;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return assignToTrainerOrAdmin.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      
    },
    onShowCreate() {
     
    },
    onShowEdit() {
     
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>