<template>
  <div class="media_gallary">
    <div class="md-layout-item md-layout md-gutter pl-0 pr-0 pb-2 p-1 mt-2 m-3 header">
        <div class="md-layout-item flex align-center text-center p-0">
            <FileInputComponent @input="input"/>
            <div v-if="isUploading" class="loader">Uploading...</div> 
        </div>
    </div>
    <Gallery @input="input1" :getMaterials="getMaterials" :isUploading="isUploading"/>
  </div>
</template>

<script>
import FileInputComponent from "@/components/atom/form/mediaInputComponent";
import Gallery from "@/components/atom/form/Gallary";
import {

} from "@/components"
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
import {mapActions,mapGetters } from "vuex";
export default {
name: "media",
components: {
  FileInputComponent,
  Gallery,
 
},
data() {
  return {
    isUploading: false,
    url: null ,
    path:this.$route.query.type,
    material_id: '',
    form: {
      file_type: "",
      file_name: "",
      thumbnail_url: "",
      file_path: "",
      file_url: "",
      is_downloadable: true,
      material_description: "",
      is_special: false,
      is_hidden_from_student: false,
      active: true,
      tag_data: [],
      file_for: ''
    }
  }
},
props: {
  type_name: {
    type: Object
  },
  materialIds: {
    type: [Array, String]
  }
},
computed: {
  ...mapGetters({
    getMaterials: "material/getMaterials",
  }),
},
methods: {
      ...mapActions({
        actMaterialsCreate: "material/actMaterialsCreate",
        actMaterialList: "material/actMaterialList",
        actGetMaterials: "material/actMaterials",
      }),
      async input(val){
        this.url = {url: URL.createObjectURL(val[0]),type: val[0].type,name: val[0].name};
        this.$emit('input',val);
        //call file
        let CustomData = val[0];
        this.CustomData = URL.createObjectURL(CustomData)
        this.isUploading = true;
        this.filepath = this.type_name.name.toLowerCase()+'/'+this.url.type+'/'+this.type_name.id.toLowerCase();
        try {
          await putObject(CustomData, this.path + '/' + this.filepath, "public-read")
            .then((res) => {
              this.isUploading = false; 
              this.onSubmit(); 
              console.log(res)
            })
            .catch(error => {
              this.isUploading = false;
              console.error(error);
            })
          }
            catch (error) {
          this.isUploading = false;
          console.error('File upload error:', error);
        }
      },
      onSubmit(){
        const type = this.url.type.split('/')
        if(type[0] === 'application'){
          if(type[1] === 'pdf'){
              this.form.file_type = type[1].toUpperCase()
              console.log('this.form.file_type',this.form.file_type)
          } else {
              this.form.file_type = 'DOCX'
              console.log('this.form.file_type1',this.form.file_type)
          }
           
        }
        if(type[0] != 'application') {
          const types = this.url.type.split('/')
          this.form.file_type = types[0].toUpperCase()
        }
        this.form.file_name = this.url.name;
        this.form.file_path = this.type_name.name.toLowerCase() + '/' + this.url.type + '/' + this.type_name.id.toLowerCase() + '/' + this.url.name;
        this.form.file_url = baseUrl + this.path + '/' + this.type_name.name.toLowerCase() + '/' + this.url.type + '/' + this.type_name.id.toLowerCase() + '/' + this.url.name;
        this.form.file_for = this.type_name.name + '';
        this.form.thumbnail_url = this.form.file_url;

        this.actMaterialsCreate(this.form)
          .then(({material_id }) => {
            this.material_id = material_id
            if(material_id){
              setTimeout(()=> {
                this.actMaterialList(``);
              },500)
            }
        }).catch(error => alert(error))
      },
      input1(val){
       console.log(val)
       this.$emit('input',val);
      },
      onDetails() {
      },
},
created() {
  this.actMaterialList(`?selected_ids=${this.materialIds}`);
}
};
</script>
