export const lists = []

const titles = ['Population of Canada', 'Our beautiful Bangladesh', 'We are believing the situation', 'Large changes of environment', 'New world going to dead', 'Technology is power', 'English is our second language']

for (let i = 0; i < 100; i++) {
    lists.push({
    id: i,
    fId: 'Q10'+i,
    title: titles[Math.floor(Math.random() * 6)],
    fileType: 'Docs',
    bulk: [1,5,8,9,10,16].includes(i),
    usedFor: 'PTE2 | Practice 4 | Mock 2',
    highFrequency: true,
    createdAt: '25 May, 2020, 9:15am',
    createdBy: 'Kazi Shahin',
    status: 'Active'
  });
}


export const assignToTrainerOrAdmin = [];

for (let i = 0; i < 100; i++) {
    assignToTrainerOrAdmin.push({
    id: i,
    fId: 'Q10'+i,
    title: titles[Math.floor(Math.random() * 6)],
    section: 'Reading',
    bulk: [1,5,8,9,10,16].includes(i),
    fileType: 'pdf',
    branch: 'Dhanmondi',
    assignAt: '25 May, 2020, 9:15am',
    assignTo: 'Kazi Shahin',
    status: (i < 15) ? 'Approved' : 'Not Approved'
  });
}


export const explanation = [];


for (let i = 0; i < 100; i++) {
  explanation.push({
  id: i,
  expId: 'Q10'+i,
  section: 'Reading',
  fileType: 'pdf',
  createdAt: '25 May, 2020, 9:15am',
  status: (i < 15) ? 'Active' : 'In active'
});
}
